import React from "react"

import Input from "./../input/input"
import TextArea from "./../text-area/textArea"

import "./emailForm.scss"
import Button from "./../button/button"

var EmailForm = props => (
  <form
    onSubmit={props.sendEmail}
    className={`email-form-component ${props.className || ""}`}
  >
    <div className="inputs">
      <Input
        className="name-input"
        error={props.nameError && props.nameError.length > 0}
        errorMessage={props.nameError}
        name="your name"
        placeholder="First Last"
        value={props.name}
        onChange={props.onNameChange}
      />
      <Input
        error={props.emailError && props.emailError.length > 0}
        errorMessage={props.emailError}
        type="email"
        name="your email"
        placeholder="example@gmail.com"
        value={props.email}
        onChange={props.onEmailChange}
      />
    </div>
    <TextArea
      error={props.messageError && props.messageError.length > 0}
      errorMessage={props.messageError}
      height="25rem"
      name="your message"
      placeholder="Compose your message here..."
      value={props.message}
      onChange={props.onMessageChange}
    />
    <Button className="send" invert type="submit">
      SEND
    </Button>
  </form>
)

export default EmailForm
