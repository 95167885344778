import React from "react"

import PageBody from "../components/page-body/pageBody"
import SEO from "../components/seo"
import Banner from "../components/banner/banner"
import bg from "../images/banners/contact-us.jpg"
import BorderTitle from "./../components/border-title/borderTitle"
import MapCard from "./../components/map-card/mapCard"
import EmailForm from "./../components/email-form/emailForm"
import { axios, TOAST_TIMER } from "../utils/utils"

import "./contact-us.scss"
import Toast from "./../components/toast/toast"
import Loader from "./../components/loader/loader"
import { contactUsPage } from "../utils/text"

class ContactUs extends React.Component {
  constructor(props) {
    super()
    this.state = {
      name: "",
      email: "",
      message: "",
      nameError: "",
      emailError: "",
      messageError: "",
      showErrorToast: false,
      showSuccessToast: false,
      loading: false,
    }
  }

  onInputChange = name => {
    return e => {
      this.clearErrors()
      this.setState({ [name]: e.target.value })
    }
  }

  clearErrors = () => {
    this.setState({ nameError: "", emailError: "", messageError: "" })
  }

  validateForm = () => {
    if (!this.state.name.length > 0) {
      this.setState({ nameError: "Please fill in your name" })
      return false
    }
    if (!this.state.email.length > 0) {
      this.setState({ emailError: "Please fill in your email" })
      return false
    }
    if (!this.state.message.length > 0) {
      this.setState({ messageError: "Please write your message" })
      return false
    }
    return true
  }

  toastTimer = toastName => {
    return () => {
      setTimeout(() => {
        this.setState({ [toastName]: false })
      }, TOAST_TIMER)
    }
  }

  showSuccessToast = () => {
    this.setState(
      {
        showSuccessToast: true,
      },
      this.toastTimer("showSuccessToast")
    )
  }

  showErrorToast = () => {
    this.setState(
      {
        showErrorToast: true,
      },
      this.toastTimer("showErrorToast")
    )
  }

  sendEmail = e => {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false)
    if (this.validateForm()) {
      this.setState({ loading: true })
      axios
        .post("/email", {
          name: this.state.name,
          email: this.state.email,
          message: this.state.message,
        })
        .then(() => {
          this.showSuccessToast()
          this.setState({ loading: false, name: "", email: "", message: "" })
        })
        .catch(err => {
          this.showErrorToast()
          this.setState({ loading: false })
        })
    }
  }

  render() {
    return (
      <>
        <SEO title="Home" keywords={["gatsby", "application", "react"]} />
        <Toast show={this.state.showSuccessToast} success>
          Email sent successfully!
        </Toast>
        <Toast show={this.state.showErrorToast} error>
          Error while sending email
        </Toast>
        <div className="contact-us-page">
          <Banner
            src={bg}
            title={contactUsPage.banner.title}
            subTitle={contactUsPage.banner.subtitle}
            height="27.5rem"
            backgroundPosY="20%"
          />
          <PageBody className="page">
            {/* <BorderTitle>Locations</BorderTitle> */}
            {/* <div className="maps">
              {contactUsPage.locations.map(location => (
                <MapCard
                  url={location.url}
                  key={location.name}
                  className="map"
                  name={location.name}
                  location={location.location}
                  address={location.address}
                />
              ))}
            </div> */}
            {this.state.loading ? (
              <div className="email-loader-wrap">
                <Loader className="email-loader" />
              </div>
            ) : (
              <EmailForm
                sendEmail={this.sendEmail}
                onNameChange={this.onInputChange("name")}
                onEmailChange={this.onInputChange("email")}
                onMessageChange={this.onInputChange("message")}
                nameError={this.state.nameError}
                emailError={this.state.emailError}
                messageError={this.state.messageError}
              />
            )}
          </PageBody>
        </div>
      </>
    )
  }
}

export default ContactUs
